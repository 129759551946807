<template>
	<Chat />
</template>

<script>
// import { BRow } from 'bootstrap-vue'
import Chat from './chat/Chat'

export default {
	name: 'Projects',
	components: { Chat },
}
</script>